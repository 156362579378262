<template>
    <div id="page-user-view">

        <div id="user-data" v-if="userInfo">

            <vx-card title="Account" class="mb-base">

                <!-- Avatar -->
                <div class="vx-row">

                    <!-- Avatar Col -->
                    <div class="vx-col" id="avatar-col">
                        <div class="img-container mb-4">
                            <img :src="userInfo.avatar" class="rounded w-full"/>
                        </div>
                    </div>

                    <!-- Information - Col 1 -->
                    <div class="vx-col flex-1" id="account-info-col-1">
                        <table>
                            <tr>
                                <td class="font-semibold">Username</td>
                                <td>{{ userInfo.username }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Name</td>
                                <td>{{ userInfo.fullName }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Email</td>
                                <td>{{ userInfo.email }}</td>
                            </tr>
                        </table>
                    </div>
                    <!-- /Information - Col 1 -->

                    <!-- Information - Col 2 -->
                    <div class="vx-col flex-1" id="account-info-col-2">
                        <table>
                            <tr>
                                <td class="font-semibold">Hoạt động</td>
                                <td>{{ userInfo.enabled }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Quyền</td>
                                <td>Admin tổ chức</td>
                                <!--<td>{{ userInfo.role }}</td>-->
                            </tr>
                            <tr>
                                <td class="font-semibold">Mã nhân viên</td>
                                <td>{{ userInfo.employeeCode }}</td>
                            </tr>
                        </table>
                    </div>

                </div>

            </vx-card>

            <div class="vx-row">
                <div class="vx-col lg:w-1/2 w-full">
                    <vx-card title="Information" class="mb-base">
                        <table>
                            <tr>
                                <td class="font-semibold">Birth Date</td>
                                <td>{{ userInfo.dob }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Mobile</td>
                                <td>{{ userInfo.mobile }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Website</td>
                                <td>{{ userInfo.website }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Languages</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Gender</td>
                                <td>{{ userInfo.gender }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Contact</td>
                            </tr>
                        </table>
                    </vx-card>
                </div>
                <!--        <vx-card class="theme-mode">-->
                <!--          <table>-->
                <!--            <tr>-->
                <!--              <td>-->
                <!--                <div>-->
                <!--                  <h5 class="mb-2">Chế độ xem</h5>-->

                <!--                  <div>-->
                <!--                    <vs-radio v-model="themeMode" vs-value="light" class="mr-4" vs-name="theme-mode-light">Sáng-->
                <!--                    </vs-radio>-->
                <!--                    <vs-radio v-model="themeMode" vs-value="dark" class="mr-4" vs-name="theme-mode-dark">Tối</vs-radio>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </td>-->
                <!--            </tr>-->
                <!--          </table>-->
                <!--        </vx-card>-->
            </div>

        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            userInfo: {
                enabled: 'Hoạt động',
                avatar: require("@/assets/images/portrait/small/user-avatar.png")
            }
        }
    },

    watch: {
        '$store.state.userInformation'(val) {
            this.userInfo = Object.assign({}, this.userInfo, val);
        }
    },

    computed: {
        themeMode: {
            get() {
                return this.$store.state.theme
            },
            set(val) {
                let themeMode = val || 'light';
                localStorage.setItem('themeMode', themeMode);
                this.$store.dispatch('updateTheme', themeMode);
            }
        },
    },

    created() {
        this.userInfo = Object.assign({}, this.$store.state.userInformation, this.userInfo)
        let themeMode = localStorage.getItem('themeMode');
        if (!themeMode)
            localStorage.setItem('themeMode', 'light');

        this.themeMode = themeMode;
    },
    mounted() {
        this.setVerticalNavMenuWidth()
    },
}

</script>

<style lang="scss">
#avatar-col {
    width: 10rem;
}

#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: .8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

.theme-mode {
    width: 200px;
    height: 100px;
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }

}
</style>
